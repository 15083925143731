.attachments-container {
  font-size: small;
  flex-wrap: wrap;
  .dropzone-container {
    display: flex;
  }
  .dropzone {
    border: 1px dotted #15375c;
    text-align: center;
    border-radius: 0px;
    font-family: inherit;
    font-weight: 500;
    font-size: small;
    padding: 5px;
    margin-bottom: 15px;
    width: 100%;
  }

  .attachments-list {
    .label-content {
      width: 100%;
      border-radius: 0px !important;
      background-color: #15375C;
      font-size: small;
    }
  }
}

@media (min-width: 1200px) {
  .dropzone-container {
    height: 100%;
    padding: 0;

  }
}