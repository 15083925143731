.react-autosuggest__container {
  position: relative;
}

.react-select__menu-list {
  max-height: 225px !important;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 3px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  // top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    width: 100%;
    float: left;
  }
  .suggestion_name{
    width: 60%;
    float: left;
    word-break: break-all;
    font-size: 14px;
	color: black;
  }
  .progress{
    width: 40%;
    float:left;
    margin-bottom:5px;
  }
  .progress-bar{
    color: white;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.suggestions {
  display: flex;
  input {
    float: left;
  }
}
.downloaded-doc {
  display: flex;
  align-items: center;
  width: 100%;
  .picky {
    width: 100%;
    border-radius: 3px;
    button {
      height: 55px;
    }
    .picky__input:after {
      top: 22px;
    }
    .picky__input {
      border: solid rgba(200,200,200,.75) 1px;
      border-radius: 3px;
    }
    .picky__placeholder {
      display: block!important;
    }
  }
  .data-reuse-submit {
    margin-top: 0px !important;
    height: 38px !important;
  }
}
.react-autosuggest__container {
  width: 100%;
}
