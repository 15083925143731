.teamList {
  position: relative;
  margin-top: 6rem;
  padding-bottom: 4rem;
  background-color: #fff;
  padding-top: 5px;
  text-align: left;

  .row {
      margin: 0; 
      
    .arrows {
      position: absolute;
      top: 5.5rem;
      right: 11%;
    }

    .arrow {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #e8e8e8;
      margin-right: 5px;
      font-size: 1.7rem;
      text-align: center;
      color: #bbb;
      cursor: pointer;
      line-height: 24px;
      vertical-align: text-bottom;
    }

    .teamMember {
      display: none;
      font-weight: 500;
      
      .image-container {
          display: inline-block;
          position: relative;

          &:after {
            padding-top: 100%;
            display: block;
            content: '';
          }
          
          img {
              position: absolute;
              object-fit: cover;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
          }
      }
    }
    

    .teamMember.visible {
      display: block;      
    }

    .grey {
      background-color: #777;
      width: 100%;
      cursor: pointer;
      margin-bottom: 25px;
    }

    .name {
      font-size: 1.4rem;
    }

    .position {
      font-weight: 500;
      margin-bottom: 15px;
    }

    .description {
      margin-bottom: 30px;
      font-size: 0.9rem;
      line-height: 1.5rem;
    }

    .position, .description {
      color: #5e6977;
    }
  }
}