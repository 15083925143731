.UserForm {
  margin-top: 95px;
  font-size: 0.9rem;
  display: inline-block;
  width: 100%;

  label {
    font-weight: 500 !important;
  }

  form {
    padding: 0;
  }

  .form-actions {
    margin-bottom: 4em;

    .btn,
    .btn-primary {
      margin-right: 1em !important;
    }
  }

  .boron-modal {
    h2 {
      margin-bottom: 2rem !important;
    }

    .form-actions {
      margin-top: 0;
      padding-top: 0;
    }
  }
  .image-container img {
    width: 200px !important;
    height: 200px !important;

  }
}
