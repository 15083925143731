.newsList {
  background-color: #f9f9f9;
  padding-top: 5px;
  margin-top: 4rem;
  text-align: left;

  .row.active {
    display: block;
  }

  .row {
    display: none;
    border: 2px solid #e1e8ee;
    margin: 0;
  }

  .leftContainer {
    padding: 0;
    .newsImage {
      width: 100%;
    }
  }

  .rightContainer {
    padding: 3% 5%;
    font-size: 75%;

    .newsTitle {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .newsDate {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
      color: #2b2b2b;
      font-weight: 500;


      .date {
        color: #86939e;
      }

      .divider {
        color: #c3cfd8;
        margin: 0 20px;
      }
    }

    hr {
      margin-top: 0;
      margin-bottom: 20px;
      color: #eeeeee;
    }

    .newsContent {
      color: #43484d;
      font-size: 0.9rem;
      line-height: 1.5rem;
      font-weight: 500;
      margin-bottom: 0;
      word-wrap: break-word;
    }
    a {
        font-size: 0.9rem;
        font-weight: 500;
    }
  }

  ul.bullets {
    text-align: center;
    padding-left: 0;
    padding-bottom: 60px;
    margin-bottom: 0;
    .bullet.active {
      background: rgba(137, 147, 158, 1);
    }

    .bullet {
      cursor: pointer;
      margin: 25px 2px;
      width: 10px;
      height: 10px;
      background: rgba(137, 147, 158, .4);
      border-radius: 50%;
      display: inline-block;
    }
  }
}
