.navigation-left-tabs {
  margin-top: 30px;
  //display: inline-block;
  background: #fff;
  height: 60px;

  a {
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &.btn {
      margin-right: 0rem;
      // min-width: 10rem;
      border-radius: 3px;
      // padding: 18px 46.68px;
      padding: 18px 14.68px;
      font-size: 16px;
      border: none !important;
      height: 58px;
      margin-right: 0px !important;
      i {
        display: none;
      }
      &:hover {
        color: #fff;
      }
    }


    &.btn-primary {
      // padding: 17px 81.6px;
      background: #c29032 !important;
      border: none !important;
      font-size: 16px !important;
    }
  }

  .left-menu {
    .btn {
      width: 100%;
    }
    a {
      margin-bottom: 10px;
    }
  }
}


@supports (-moz-appearance: none) {
  .navigation-left-tabs a.btn {
    margin-right: 0;
    border-radius: 3px;
    padding: 18px 44.9px;
    font-size: 16px;
    border: none !important;
    height: 58px;
    margin-right: 0 !important;
  }

  .navigation-left-tabs a.btn-primary {
    // padding: 17px 57px;
    background: #c29032 !important;
    border: none !important;
    font-size: 16px !important;
    padding-left: 58.5px;
  }

}

@media (max-width: 767px) {
  // profile  page  start

  .navigation-left-tabs {
    a {
      &.btn {
        padding: 10px 15px !important;
        height:auto;
        word-break: break-word;
        white-space: break-spaces;
      }

      span {
        display: none;
      }

      i {
        display: block !important;
        font-size: 24px !important;
        line-height: 36px !important;
      }
    }
  }

  // profile  page  end
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  .navigation-left-tabs {
  a {
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &.btn {   
      padding: 18px 16.68px;
      font-size: 16px;
      border: none !important;
      height:auto;
      word-break: break-word;
      white-space: break-spaces;
      margin-right: 0px !important;
      i {
        display: none;
      }
      &:hover {
        color: #fff;
      }
    }
  }
 }
}

