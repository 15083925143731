.footer {
  background-color: #2f323b;
  color: #999;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  width: 100%;

  a.btn.btn-donate {
	color: #fff;
	background-color: #c29032;
	border-radius: 3px;
  }

  .row {
    margin: 0;

    .footerMenuContainer,
    .infoContainer,
    .contactContainer {
      margin-top: 60px;
      margin-bottom: 15px;
      min-height: 200px;
      width: calc(100% / 4);

      li {
        line-height: 35px;
      }
    }
    .socialContainer {
      margin-top: 20px;
      margin-bottom: 15px;
      width: 100%;
    }
    .infoItem {
      cursor: pointer;

      span {
        color: #999 !important;
        font-weight: 600 !important;
        display: inline-flex;
        word-break: break-all;
        line-height: 19px;
        em:before {
          font-size: 20px;
          margin-right: 5px;
        }
      }

      &:hover {
        color: #fff;
      }

      p {
        margin-bottom: 0;
        font-weight: 600 !important;
      }

      a {
        color: #999;
        font-weight: 600;

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .contactItem {
      span {
        &:first-child {
          display: inline-block;
          margin-right: 7px;
          color: #fff;
        }
      }
    }

    h3 {
      margin-bottom: 1rem;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
    }

    ul {
      list-style-type: none;
      padding: 0;
      font-size: 80%;
    }

    ul.socialIcons {
      text-align: center;
      margin-top: 20px;
    }

    li.socialIcon {
      display: inline-block;
      margin-left: 10px;
      font-size: 165%;
      border: 2px solid #4c4e56;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      color: #787b87;

      &:hover {
        background-color: #132d5e;
        color: #fff;
        border-color: #132d5e;
      }
    }
  }
  .logo {
    float: right;
    position: absolute;
	bottom: -1.9rem;
	right: 1.9rem;

  }
  .copyright-box {
    position: relative;
    .copyright {
      margin: 1rem 0 0;
      padding-bottom: 40px;
      text-align: center;
      font-size: 0.8rem;

      .blue {
        color: #c29032;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .footer {
  
	
    .donate-button {
		text-align: center;
		margin-top: 0px;
	}
    .row {
      .footerMenuContainer,
      .infoContainer,
      .contactContainer {
        margin-top: 20px;
        min-height: 200px;
        width: calc(100% / 4);
        position: initial;
      }
      .socialContainer {
        width: 100%;
        min-height: 0;
        .socialIcons,
        .logo {
          vertical-align: middle;
        }
		
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    .row {
      .footerMenuContainer,
      .infoContainer,
      .contactContainer {
        margin-top: 35px;
        min-height: 200px;
        width: calc(100% / 2);
        display: inline-block;
        vertical-align: top;
        text-align: left !important;
      }
	  
	  
      h3 {
        font-size: 1.1rem;
      }
      ul {
        font-size: 0.9rem;
        margin-bottom: 40px;
      }
    }
    .copyright-box {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      .logo {
        position: static;
      }
    }
  }
}

@media only screen and (max-width: 482px) {
  .footer {
    .row {
      .footerMenuContainer,
      .infoContainer,
      .contactContainer {
        margin-top: 20px;
        width: 100%;
        display: block;
        min-height: 0;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .footer {
    .container-row {
      .row {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}
