/*
 * Bootstrap Customization
 */
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// General
$brand-primary: #132d5e;
$brand-danger: #e23f3f;
$border-radius-small: 0px;
$border-radius-base: 0px;
$border-radius-large: 0px;

// Navbar
$navbar-default-bg: #fff;
$navbar-padding-horizontal: 0;
$navbar-padding-vertical: 0;

// Buttons
$btn-default-bg: #eee;
$btn-default-border: #eee;

// Pagination
$pagination-bg: transparent;
$pagination-hover-bg: transparent;

@import "~bootstrap-sass/assets/stylesheets/_bootstrap";

.transation-2s-in-out {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.transation-3s-in-out {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.transation-5s-in-out {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #43484d;
  #popover-positioned-left,
  #popover-positioned-right,
  #uploadFileTypesTooltip,
  .commentPopover {
    opacity: initial;
    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
      i {
        margin-right: 0px;
        font-size: 34px;
      }
    }
  }
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: small;

  // [class*="react-datepicker"] {
  //   width: 100%;
  // }

  // .container {
  // 	position: relative;
  // 	width: 100%;
  // 	height: 100%;
  // }

  .container-row {
    margin: 0 auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 1200px;
  }
  @media (max-width: 1400px) {
    .container-row {
      max-width: 100%;
    }
  }

  // ul.nav-tabs {
  //   display: inline-block;
  //   border: none;

  //   li {
  //     padding-right: 2.5rem;
  //     margin-bottom: 10px;
  //     &.active {
  //       a,
  //       a:hover {
  //         border-bottom: 0.3rem solid #c29032;
  //       }
  //     }

  //     a,
  //     a:hover {
  //       border: none;
  //       padding: 1.9rem 0 0.6rem 0;
  //       text-transform: uppercase;
  //       color: #333;
  //       cursor: pointer;
  //       background-color: transparent;
  //     }
  //   }
  // }

  .nav-tabs {
    padding-bottom: 5px;
    a {
      margin-right: 2.5rem;
      margin-bottom: 10px;
      padding-bottom: 5px;
      text-decoration: none;
      &.active {
        border-bottom: 0.3rem solid #c29032;
      }
    }
  }
  .tab-pane{
    opacity: initial;
    padding-top: 10px;
  }

  .boron-modal {
    & > div:first-child {
      padding: 20px 5%;
      outline: none;
      font-size: 0.9rem;

      .scrollable {
        max-height: 30rem;
        overflow-y: scroll;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 0.7rem;
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      p {
        color: #43484d;
        font-size: 0.9rem;
      }

      .form-group {
        .form-control {
          padding: 20px 12px;
          border: 1px solid #d7dee4;
          border-radius: 3px;
          box-shadow: none;
        }
      }

      .form-actions {
        padding-top: 0.7rem;

        .btn {
          margin-bottom: 1rem;
          margin-left: 0;
        }
      }

      .alert {
        font-size: 0.9rem;
      }
    }

    & + div {
      background-color: #15202b !important;
    }
  }

  form {
    label.control-label {
      font-weight: 400;
    }

    .dropzone {
      background-color: #eee;
      max-width: 20rem;
      height: 12rem;
      font-weight: 300;
      border: 1px dashed #c0c0c0;
      color: #a9a9a9;
      text-align: center;
      line-height: 12rem;
      position: relative;
      cursor: pointer;

      &:hover {
        &:before {
          // content: 'Click or drop to upload';
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(51, 153, 255, 0.4);
        }
      }

      img {
        margin-top: -0.4rem;
        width: auto !important;
        max-height: 160px;
      }
    }

    .form-group {
      text-align: left;
      color: #333;

      .form-control {
        //padding: 20px 12px;
        border: 1px solid #d7dee4;
        border-radius: 3px;
        box-shadow: none;
      }
    }

    .form-actions {
      margin-top: 2rem;
    }
  }

  .form-actions {
    button {
      &:nth-child(1) {
        margin-right: 0.4rem;
      }

      &:nth-child(2) {
        margin-left: 0.4rem;
      }
    }
  }

  .btn {
    border-radius: 3px;
    padding: 9px 15px;

    &:hover/*,
		&:active,
		&:focus,
		&:visited */ {
      outline: none;
      text-decoration: none !important;
      color: #fff;
      background-color: #132d5e;
      border-color: #132d5e;
    }

    &.btn-primary {
      background-color: #132d5e;
      border-color: #132d5e;

      &:hover {
        background-color: darken(#132d5e, 15%);
        border-color: darken(#132d5e, 15%);
      }
    }

    &.btn-secondary {
      background-color: #c29032;
      border-color: #c29032;
      color: #fff;
      opacity: 1;

      &:hover {
        background-color: darken(#c29032, 15%);
        border-color: darken(#c29032, 15%);
        color: #fff;
      }
    }
    &.btn-success {
      background-color: #c29032;
      border-color: #c29032;
      color: #fff;
      opacity: 1;

      &:hover {
        background-color: darken(#c29032, 15%);
        border-color: darken(#c29032, 15%);
        color: #fff;
      }
    }

    &.btn-warning {
      background-color: #f0ad4e;
      border-color: #f0ad4e;
      color: #fff;
      opacity: 1;

      &:hover {
        background-color: darken(#f0ad4e, 15%);
        border-color: darken(#f0ad4e, 15%);
        color: #fff;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .flagSelect {
    .arrow-down,
    .selected--flag--option {
      color: #fff;
    }
  }

  @media (max-width:480px) {
    .logo {
      position: relative;
      float: left;
    }
  }

  @media (min-width:481px) and (max-width:1400px) {
    .logo {
      position: fixed;
      //margin-left: calc(50% - 75px);
      display: inline-block;
      top: 35px;
    }
  }

  @media (min-width: 1401px) {
    .logo {
      position: fixed;
      //margin-left: 540px;
      display: inline-block;
      top: 35px;
    }
    .leftmenu {
      float: left; 
    }
  }

  .logo {
    position: relative;
    color: #fff;
    text-align: center;
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    z-index: 1;
    font-weight: 600;
    max-width: 100%;
    img {
      max-height: 100px;
      max-width: 180px;
    }

    span:first-child {
      color: #132d5e;
    }
  }
}

.ion-help-circled {
  color: #132d5e;
}

.ion-alert-circled {
  color: red;
}

.cookie-text {
  font-size: 13px;
}

.cookieConsent {
  padding-right: 75px;
}

@media (min-width: 481px) and (max-width: 770px) {
  .App .logo {
    max-width: 140px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .App .logo {
    text-align: center;
    width: 140px;
    margin: 0px auto 2rem;
    float: none;
    padding-top: 3rem;
  }
  .App .logo img {
    max-width: 150px;
    max-height: 50px;
  }
}

.tooltip-inner {
  max-width: 250px !important;
  padding: 3px 6px;
  word-break: break-all;
  li {
    text-align: left;
    font-family: inherit;
    font-size: 14px;
  }
}
