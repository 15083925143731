@import "../../../mixins.scss";

.AlertList {
  margin-top: 1rem;
  font-size: 0.9rem;
  background-color: #ffffff !important;
  .panel-default{
		background-color: #f7f8fa;
		overflow: auto;
    height: 100%;
  }
  .DocumentCategoryFilter {
    float: left !important;
  }

  @include noselect;

  table {
    thead {
      // border: 1px solid #bfbfbf;

      .column-documentName {
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-left-radius: 10px;
      }

      .column-date {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
      }
    }

    tbody {
      border: 1px solid #e1e8ee;
      border-top: none;
      margin: 20px;
      border-radius: 3px;
    }

    th {
      padding: 20px 20px !important;
    }
    td {
      position: relative;
      padding: 10px 20px !important;
      text-align: left;
      border: none !important;
    }

    a {
      color: #000000;
    }

    a:hover {
      color: #000000;
      text-decoration: none;
    }

    .doc-name {
      color: #132d5e;
    }

    th {
      background: #132d5e;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
    }

    tr {
      &:nth-child(2n + 1) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:nth-child(2n) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:last-child {
        border-bottom: none;
        .name {
          -webkit-border-bottom-left-radius: 10px;
          -moz-border-radius-bottomleft: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      .column-file {
        -webkit-border-top-left-radius: 0px !important;
        -moz-border-radius-topleft: 0px !important;
        border-top-left-radius: 0px !important;
      }
    }
  }
}
