.counter {
    height: 260px;
    // background: url('/images/stats-bg.jpg');
    background-size: cover !important;
    background-position-y: 75% !important;

    #counterOpacity {
        background-color: rgba(210,230,255,.6);
        position: absolute;
        width: 100%;
        height: 260px;
    }

    .row {
        padding-top: 75px;
        position: relative;
        width: 100%;
        margin: 0 auto;
        max-width: 1400px;

        .count {
            font-size: 3.2rem;
            font-weight: 500;
            color: #43484d;
            font-weight: 600;
        }

        .title {
            margin-top: -0.5rem;
            font-size: 1rem;
            color: #5e6977;
            font-weight: 600;
        }

        .count, .title {
            font-family: 'Montserrat', sans-serif;
            text-align: center;
        }

        .withDivider {
            &:after {
                content: "";
                position: absolute;
                top: calc(50% - 20px);
                right: -15px;
                border-right: 1px solid #43484d;
                height: 25%;
            }
        }
    }
}
