.subcate-cont {
  padding-top: 20px;

  .sub-cate1 {
    a {
      width: 100%;
      background: #fff;
      float: left;
      padding: 1.2rem;
      height: 230px;
      border: #cecece solid 2px;
      border-radius: 3px;
      -o-transition: 0.5s;
      -ms-transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      align-items: center;
      span.title {
        order: 1;
        margin-top: 19px;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    a:hover {
      background: #132d5e;
      border: #132d5e solid 1px;
      color: #ffffff;
      display: flex;
    }

    a:hover img {
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }

    img {
      width: 100px;
      float: left;
      margin-right: 15px;
      order: 2;
      height: 100px;
      margin: 0px auto 0;
    }
  }
}

.SubCategoryList {
  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 1.6rem;
  }

  ul {
    padding: 0;
    margin-bottom: 3rem;
    overflow: auto;

    li {
      overflow: auto;
      list-style: none;
      // width: 100%;

      a,
      a:hover {
        display: block;
        position: relative;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 18px;
        text-decoration: none;
        color: #000000;
        // border-bottom: 1px solid #e1e8ee;

        i.paper-icon {
          display: inline-block;
          color: #d6dade;
          margin-right: 1rem;
          font-size: 4rem;
          line-height: 4rem;
        }
      }

      a:hover {
        color: darken(#6c6c72, 5%);

        i.paper-icon {
          color: darken(#d6dade, 5%);
        }
      }

      span.title {
        // position: absolute;
        // top: 1.5rem;
        // display: inline-block;
      }

      .btn,
      .btn:hover,
      .btn:focus,
      .btn:active {
        float: right;
        background-color: transparent;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        color: #6c6c72;
        box-shadow: none;
        border: 1px solid #e1e8ee;
      }

      .btn:hover {
        border: 1px solid darken(#e1e8ee, 5%);
      }
    }
  }
}

@media (max-width: 767px) {
  .subcate-cont {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
  }
}
