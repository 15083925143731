.RequiredOptionsList {
  font-size: 0.9rem;
  margin-top: 40px;
  .panel-default {
    background-color: #f7f8fa;
    overflow: auto;
  }
  .list-group {
    margin-bottom: 0;
  }

  .list-group-item {
    background-color: #f7f8fa;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    overflow: auto;
    border-radius: 3px !important;
  }

  .ml-auto {
    margin-left: auto;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 480px) {
  .RequiredOptionsList {
  }
}

.boron-modal {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
  text-align: center;
  .close-btn {
    width: 35px;
    position: absolute;
    right: -15px;
    top: -15px;
    background: #fff;
    height: 35px;
    border-radius: 50%;
    font-size: 34px;
    line-height: 32px;
    text-align: center;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    color: #e81e1e;
    i {
      margin-right: 0px;
      font-size: 34px;
    }
  }

  .draftName {
    margin-bottom: 10px;
  }

  button {
    margin-top: 30px;
  }

  .btn {
    margin-right: 2%;
    i {
      margin-right: 10px;
    }
  }
  .shareable-fields {
    text-align: left;
  }
  .instruction-message {
    padding-top: 15px;
  }
  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 60px;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: 18px;
    background: #132d5e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn2 {
    background: #c29032 !important;
  }
}
  
