.ArticleList {
    h2 {
        margin-top: 4rem;
		margin-bottom: 2.5rem;
        text-align: left;
        font-size: 1.6rem;
    }

    ul {
  		padding: 0;

  		li {
  			list-style: none;
  			perspective: 1000px;

  			a {
				background-color: #fff;
				color: #151515;
  				position: relative;
  				display: block;
  				width: 100%;
  				margin-bottom: 30px;
  				padding: 1.2rem;
  				text-decoration: none;
				text-align: left;
				font-weight: bold;
  				border: 2px solid #d4d4d4;
				border-radius: 3px;
  				font-size: 1rem;
  			}

  			a:hover {
  				border-color: #132d5e;
				background-color: #132d5e;
				color: #fff;
  			}

  			a {
  				.categoryImage {
                                      position: relative;
  				    margin: -20px auto 0;
                                      width: 115px;
                                      height: 115px;
  				    background-repeat: no-repeat;
  				    background-size: contain;
  				}

  				p {
  					text-align: center;
  					margin-top: 0px;
  					color: #132d5e;
                                          font-weight: 500;
  				}
  			}
  		}
  	}
}
