.cookie-box-open {
  background-color: white;
  z-index: 10000;
  bottom: 0%;
  right: 0;
  max-width: 100%;
  position: fixed;
  box-shadow: 6px 4px 31px 0px rgba(0, 0, 0, 0.25);
}
.cookie-box-closed {
  background-color: white;
  z-index: 10000;
  top: 100%;
  left: 0;
  max-width: 0%;
  position: fixed;
  .triggerSlide {
    transform: translateY(-100%);
    transform-origin: left top;
    background-color: #c29032;
    float: left;
    margin-top: 0;
    padding: 6px;
    color: white;
    border-radius: 2px;
    min-width: max-content;
  }
  .cookie-form{
    display: none;
  }
}

.cookie-form {

  h4 {
    text-align: center;
  }
  padding: 20px 5%;
  text-align: left;
  background-color: aliceblue;
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .accept {
      background: #132d5e;
      color: white;
    }
    .decline {
      background: #c29032 !important;
      color: white;
    }
	margin-top: 15px;
  }

  .cookie-types {
    display: inline-flex;
    > div {
      margin-right: 15px;
      display: inline-flex;
      > input {
        margin-right: 3px;
      }
    }
  }
  .cookie-text {
    font-size: 13px;
  }
  .ion-android-cancel {
    position: fixed;
    font-size: 30px;
    right: 5px;
    margin-top: -15px;
  }
  
  p {
    margin: 20px 0px 20px 0px;
  }
  
  label {
	margin-bottom: 0px;
  }
  
  input[type=checkbox] {
	margin: 2px 0 0;
  }
  
}

.ion-android-cancel {
  color: red;
}

@media only screen and (max-width: 700px) {

  .cookie-form {
	  h4 {
		text-align: left;
	  }
  }
  [class*="feedback-box-open"] {
    background-color: white;
    z-index: 10000;
    top: 0;
    max-width: 100%;
    position: fixed;
  }
  [class*="feedback-box-closed"] {
    background-color: white;
    z-index: 10000;
    margin-top: 45%;
    max-width: 0%;
    position: fixed;
  }
  [class*="trigger"] {
    font-size: small;
  }
}
