.DocumentCategoryFilter {
  display: inline-block;
  // margin: 2.5rem 0 1rem 0;
  font-size: 0.9rem;

  label {
    font-weight: 400;
  }

  select {
    display: inline-block;
    width: inherit;
    margin-left: 1rem;
    width: 212px !important;
  }
}
