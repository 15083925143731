.Topbar {
	position: relative;
	padding-bottom: 3rem;
	// background: url('/images/main-bg.jpg');
	background-size: cover !important;
	background-position-y: 25% !important;
	background-repeat: no-repeat !important;
	text-align: center;

	.menuButton {
		display: none;
		z-index: 1;
		position: fixed;
		width: 30px;
		height: 30px;
		line-height: 27px;
		color: #fff;
		font-size: 20px;
		right: 10px;
		top: 44px;
		border: 2px solid #fff;
		border-radius: 3px;
		cursor: pointer;
	}

	.menuButtonDesktop  {
		z-index: 100;
		position: relative;
		width: 35px;
		height: 35px;
		line-height: 27px;
		color: #fff;
		font-size: 30px;
		right: 10px;
		margin-left: 15px;
		border: 2px solid #fff;
		border-radius: 3px;
		cursor: pointer;
	}

	.menuButtonDesktop .ion-navicon-round {
		position: relative;
		display: inline;
		top: 0;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.85;
		bottom: 0;
		background-color: #021c35;
	}

	.title {
		position: relative;
		padding-top: 5rem;
		color: #fff;
		text-transform: uppercase;
		font-size: 2.5rem;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		z-index: 1;
	}

	.divider {
		position: relative;
		width: 5rem;
		height: 0.25rem;
		margin: 0.5rem auto;
		background-color: #132d5e;
	}

	.documentInfo {
		position: relative;
		width: 5rem;
		height: 0.25rem;
		margin: 0.5rem auto;
		cursor: pointer;
	}

	.subtitle {
		position: relative;
		max-width: 30rem;
		margin: 1rem auto 0 auto;
		color: #e1e9f6;
		font-weight: 300;
		font-size: 1.2rem;
		line-height: 2rem;
                font-weight: 500;
	}

	.readMore {
		display: block;
		position: relative;
		color: #fff;
		font-size: 1rem;
		font-weight: 300;
		opacity: 0.8rem;
	}

	.description {
		position: relative;
		margin: 2rem auto 0 auto;
		max-width: 35rem;
		color: #fff;
		font-size: 1rem;
		font-weight: 300;
		opacity: 0.8;
	}

	#howItWorks {
		position: relative;
	    margin: 5rem auto calc(5rem - 45px) auto;
	    border: 2px solid #fff;
	    display: inline-block;
	    border-radius: 3px;
	    padding: 15px 63px 15px 22px;
	    text-align: left;
	    cursor: pointer;

		a {
			color: #fff;
			font-weight: 500;
			text-transform: uppercase;
			font-size: 0.9rem;

			&:hover {
				text-decoration: none;
			}
		}

		i {
			position: absolute;
			top: 0.4rem;
			right: 1rem;
			color: #fff;
			font-size: 2rem;
		}
	}

	.boron-modal {
		border-radius: 3px;
		-webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
		-moz-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
		box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
		// width: 600px !important;
		.close-btn {
			width: 35px;
			position: absolute;
			right: -15px;
			top: -15px;
			background: #fff;
			height: 35px;
			border-radius: 50%;
			font-size: 34px;
			line-height: 32px;
			text-align: center;
			box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
			color: #e81e1e;
			i {
				margin-right: 0px;
				font-size: 34px;
			}
		}

		.draftName {
			margin-bottom: 10px;
		}

		button {
			margin-top: 30px;
		}

		.btn{
			margin-right: 2%;
			i {
				margin-right: 10px;
			}
		}
	}

	@media (min-width:1200px) {
		.boron-modal {
			width: 600px !important;
		}
	}

	.btn1,
	.btn2,
	.btn3,
	.btn4 {
		height: 55px;
		border-radius: 3px;
		border: none;
		color: #ffffff;
		font-size: 18px;
		background: #132d5e;
		padding-left: 50px;
		padding-right: 50px;
	}

	.btn2 {
		background: #c29032 !important;
	}

	.btn3 {
		background: #535353 !important;
	}

	.btn4 {
		background: #e81e1e;
	}
}


@media only screen and (max-width: 1400px) {
  .Topbar {
    .container-row {
        padding-left: 15px !important;
        padding-right: 15px !important;

    }
  }
}

@media (max-width:770px){

	.Topbar .title {
    font-size: 2.5rem;
	}
	.Topbar {
    position: relative;
		padding-bottom: 5rem;
	}


}


@media only screen and (max-width: 767px) {
  .Topbar {
    .title {
        font-size: 2rem;
        word-break: break-all;
        padding-top: 6rem;
    }
  }
  .boron-modal.form-modal.infoModal {
		max-height: 90%;
	}
}

@media (max-width:480px){

	.Topbar .title {
    padding-top: 3rem;
  }

}
