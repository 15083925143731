.DraftsPage {
  .draft-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0 1rem 0;
    font-size: 0.9rem;

    div[class*="-control"] {
      min-height: 60px;
    }

    .DraftCategoryFilter,
    .SearchBar {
      select, input {
        margin: 0px !important;
        width: 100% !important;
      }
    }

    div[class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }

    .top-margin-5{
      margin-top: 5px;
    }

    .top-margin-10{
      margin-top: 10px;
    }
  }
}
