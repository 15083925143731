.triggerSlide{
	transform: rotate(-90deg) translateY(-100%) translateX(-100%);
	transform-origin: left top;
	background-color: #c29032;
	float: left;
	margin-top: 0;
	padding: 6px;
	color: white;
	border-radius: 2px;
}

.close-trigger-mobile {
	visibility: hidden;
}

.feedback-area{
	height: 6em;
	resize: none;
}
.feedback-box-open {
	background-color: white;
	z-index: 10000;
	top: 30%;
	right: 0;
	max-width: 40%;
	position: fixed;
	box-shadow: 6px 4px 31px 0px rgba(0, 0, 0, 0.25);
	transition: max-width .5s;

}
.feedback-box-closed {
	background-color: white;
	z-index: 10000;
	top: 30%;
	right: 0;
	max-width: 0%;
	position: fixed;
	transition: max-width .5s;

}

.feedback-form {
	padding: 40px 30px 30px;
	text-align: left;

}

.ion-android-cancel {
	color: red;
}

.feedback-label {
	font-weight: 300;
}

.feedback-button {
	margin-top: 10px;
	visibility: visible;
}



@media only screen and (max-width: 700px) {
  [class*="feedback-box-open"] {
    background-color: white;
	z-index: 10000;
	top: 0;
	max-width: 100%;
	position: fixed;
  }
  [class*="feedback-box-closed"] {
    background-color: white;
	z-index: 10000;
	margin-top: 45%;
	max-width: 0%;
	position: fixed;
  }
  [class*="trigger"] {
	font-size: small;
  }
  [class*="close-trigger-mobile"] {
	visibility: visible;
	text-align: right;
  }
  .feedback-form {
	padding: 0px 20px 20px;

}
 
  
}