.NavigationTabs {
  // margin-top: 80px;
  //display: inline-block;
  background: #ededed;
  border: #cecece solid 1px;
  border-radius: 3px;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  a {
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &.btn {
      margin-right: 0rem;
      // min-width: 10rem;
      border-radius: 3px;
      padding: 0 0;
      font-size: 16px;
      border: none !important;
      height: 58px;
      line-height: 58px;
      margin-right: 0px !important;
      margin-left: 5px;
      
      i {
        display: none;
      }
      &:hover {
        color: #fff;
      }
    }
   
    &.btn-primary {
      // padding: 17px 81.6px;
      background: #c29032 !important;
      border: none !important;
      font-size: 16px !important;
    }
  }


  .left-menu {
    .btn {
      width: 100%;
    }
    a {
      margin-bottom: 10px;
    }
  }
}


.NavigationTabs a:first-child {
  margin-left: 0;
}
.NavigationTabs a:last-child {
  margin-left: 6.6px;
}

.NavigationTabs.navigation-settings-menu a:last-child {
  margin-left: 0px;
}

@supports (-moz-appearance: none) {
  .NavigationTabs a.btn {
    margin-right: 0;
    border-radius: 3px;
    padding: 0 0;
    font-size: 16px;
    border: none !important;
    height: 58px;
    margin-right: 0 !important;
    line-height: 58px;
    margin-left: 5px;
    
  }

  .NavigationTabs a.btn-primary {
    // padding: 17px 57px;
    background: #c29032 !important;
    border: none !important;
    font-size: 16px !important;
   }
   .NavigationTabs a:first-child {
      margin-left: 0;
    }

  .NavigationTabs a:last-child {
    margin-left: 6.5px;
  }
}

@media (max-width: 768px) {
  // profile  page  start

  .NavigationTabs {
    margin-top: 40px;
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    height: auto;
    a {
      &.btn {
        padding: 10px 0px !important;
        background: none;
        height: auto;
        width: 12.7vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:active{
          box-shadow: none;
          background: none
        }
        &:hover{
          background: none !important;
          box-shadow: none;
        }
      }
      &.btn-primary{
	    &:nth-last-child(2) {
		  i{
            &::after{
              display: none;
            }
          }
		}
        &:last-child{
          i{
            &::after{
              display: none;
            }
          }
        }
        background: none !important;
        i{
		  margin-top: 35px !important;
          background: #c29032!important;
          border-radius: 50%;
          font-size: 6vw!important;
          padding: 0;
          width: 12.5vw;
          height: 12.5vw;
          border: 2px solid #fff !important;
          box-shadow: 0px 0px 6px #999;
          transform: scale(1.2);
          order: 2;
          display: flex !important;
          align-items: center;
          &::after{
            background: #fff;
            height: 5px;
            width: 100%;
            content: "";
            position: absolute;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            top: 43%;
            left: 102%;
          }
          &::before{
            margin:0 auto;
          }
        }
        span{
		  display: block;
		  position: absolute;
		  font-size: large;
          color:#c29032 !important;
        }
      }
      &.btn-default{
        &:last-child{
          i{
            &::after{
              display: none;
            }
          }
        }
        i{
		  margin-top: 40px !important;
          background: #fff!important;
          border-radius: 50%;
          padding: 0;
          border: 1px solid #ccc;
          font-size: 5vw!important;
          width: 10vw;
          height: 10vw;
          margin: 0 auto;
          color: #333;
          order: 2;
          position: relative;
          display: flex !important;
          align-items: center;
          &::after{
            background: #fff;
            height: 5px;
            width: 100%;
            content: "";
            position: absolute;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            top: 43%;
             left: 100%;
          }
          &::before{
            margin:0 auto
          }
        }
      }
      span {
	    display: none;
        font-size: 16px;
        word-break: break-word;
        white-space: normal;
        line-height: 16px;
        color: #333;
        font-weight: 700;
        align-items: unset;
        order: 1;
        margin-bottom: 30px;
        height: 32px;
        overflow: hidden;
        letter-spacing: -0.5px;
      }

      i {
        display: block !important;
        font-size: 24px !important;
        line-height: 36px !important;
      }
    }
  }
  .navigation-left-tabs {
    margin-top: 80px;
    background: #fff;
    height: auto !important;
    .left-menu .btn {
      width: 100%;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .UserSettingsForm .row-eq-height {
    display: block !important;
  }

  // profile  page  end
}


@media (max-width: 640px) {

  .NavigationTabs {
    a {
      span {
        font-size: 12px;
        word-wrap: break-word;
        white-space: normal;
        line-height: 15px;
      }
    }
  }
  

}

@media (max-width: 480px) {

  .NavigationTabs {
    a{
      &.btn {
        width: 11.9vw;
      }
    }
    a.btn-default {
      i {
        padding: 0;
        border: 1px solid #ccc;
        font-size: 5vw !important;
        text-align: center;
        &:after {
          width: 100%;
        }
      }
    }
    a.btn-primary {
      i {
        padding: 0;
        &:after {
          width: 100%;
        }
      }
    }
    a.btn {
      padding: 10px 0px !important;
    }
    a {
      span {
        font-size: 11px;
        margin-bottom: 19px;
        word-break: break-all;
      }
    }
  }
 
}
