.pagination-wrapper {
  text-align: center;
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px auto;
    border-radius: 3px;
    // width: 70%;
    background: #fff;
    padding: 5px;
    border: #cecece solid 1px;

    li {
      &.active {
        a {
          color: #ffffff !important;
        }
      }

      display: inline-block;

      a {
        border: none;
        color: #a7a7a7;
        font-size: 14px;
        font-weight: 500;
        padding: 9px 19px;
        border-radius: 3px;
        margin-top: 5px;
      }
    }

    li:last-child a {
      color: #000000;
      font-size: 16px;
    }
  }

  .arrow-left {
    font-size: 24px !important;
    line-height: 8px;
    margin-top: 10px !important;
    padding-top: 0px !important;
    color: #132d5e !important;
  }

  .arrow-right {
    font-size: 24px !important;
    line-height: 8px;
    margin-top: 10px !important;
    padding-top: 0px !important;
    color: #132d5e !important;
  }
}
