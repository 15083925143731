.LanguageSelector {
    float: right;
    position: relative;
    height: 1.5rem;
    margin-top: 0.5rem;
    

    &:hover {
        .country-label,
        .arrow-down {
            opacity: 1 !important;
        }
    }

    .form-group {
    	margin: 0;
    }

    .flag-select {
    	.flag-options {
    		margin-top: 6px;
    	}
        .selected--flag--option {
            &:before {
                right: 0;
            }
        }
    }

	.country-flag {
		font-size: 0.8rem;
                @media (max-width: 767px) {
                    font-size: 0.9rem;
                }

		img {
			top: 0;
                        margin-top: -2px;
		}

        .country-label {
            opacity: 0.5;
        }
	}

	.arrow-down {
		margin-left: 0.3rem;
		font-size: 0.7rem;
        opacity: 0.5;
	}
}