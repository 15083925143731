.breadcrumb-cont {
    background-color: #f9f9f9;
}

.Breadcrumbs {
	padding: 25px 0;
    font-size: 0.9rem;

	a {
		color: #5e6977;

		&:hover {
			color: #5e6977;
		}
	}

	span.divider {
		margin: 0 1rem;
		color: #5e6977;
	}
}


@media only screen and (max-width: 1400px) {
    .Breadcrumbs {
        .container-row {
            padding: 0 15px !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .Breadcrumbs {
        display: none;
    }
}