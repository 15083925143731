.CategoryList {
    i.folder-icon{
      font-size: 120%;
      margin-right: 10px;
	}
	
	.flip-container {
		display: inline-block;
		perspective: 1000px;
		height: 230px;
		width: 33.33%;
	}

	@media (max-width: 800px) {
		.flip-container {
			display: inline-block;
			perspective: 1000px;
			width: 100%;
		}
	}
  
	.front, .back {
		backface-visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.front a {
		text-align: right;
                img {
                    width: 40px;
                }
	}


	.front {
		z-index: 2;
	}


	.back {
		transform: rotateY(180deg);
	}
  
  
	.rotate {
		transform: rotateY(180deg);
	}
  
  
	.flipper {
		transition: 0.6s;
		transform-style: preserve-3d;
		background: #f35e59;
		position: relative;
		width: 100%;
	}

        .back {
            padding: 20px;
            p {
		font-weight: 500;
		color: #999!important;
		text-align: left;
                font-size: 1rem;
                &.stats-heading {
                    font-weight: 700;
                }
                &.closeButton {
                    float: right;
                    cursor: pointer;
                    margin: 10px 20px 0; 
                    border: 1px solid #999;
                    padding: 2px;
                    text-align: center;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    font-size: 1.1rem;
                }
            }
        }
	

	ul {
		padding: 0;
		
		li {
			list-style: none;
			perspective: 1000px;		

			a {
				background-color: #fff;
				color: #151515;
  				position: relative;
  				display: block;
  				width: 100%;
  				margin-bottom: 30px;
  				padding: 1.2rem;
  				text-decoration: none;
				text-align: left;
				font-weight: bold;
  				border: 2px solid #d4d4d4;
				border-radius: 3px;
  				font-size: 1rem;
  			}

  			a:hover {
  				border-color: #132d5e;
				background-color: #132d5e;
				color: #fff;
  			}

			a {
				.categoryImage {
                                    position: relative;
				    margin: -20px auto 0;
                                    width: 115px;
                                    height: 115px;
				    background-repeat: no-repeat;
				    background-size: contain;
				}

				p {
					text-align: left;
					margin-top: 0px;
                                        font-weight: 500;
				}
			}
		}
	}
}