.Timeline {
  padding: 50px 10px;
  width: 60%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  &__Nodata {
    &::before {
      display: none !important;
    }
  }
  &::before {
    content: "";
    width: 2px;
    height: calc(100% - 40px);
    top: 20px;
    left: 0;
    display: block;
    position: absolute;
    background-color: #15375c;
  }
  &__Item {
    display: flex;
    align-items: stretch;
    margin-bottom: 40px;
    position: relative;
    &__Duration {
      position: absolute;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform-origin: 0% 0%;
      top: 90px;
      left: 8px;

      background: white;
      padding: 2px 8px;
      border-radius: 10px;
      border: 1px solid black;

      max-width: 200px;
      text-align: center;
    }
    &__Icon {
      width: 30%;
      width: 50px;
      padding: 10px 0px;
      text-align: center;
      background: #15375c;
      border-radius: 35px 0 0 35px;
      height: 70px;
      padding-left: 15px;
      position: relative;
      img {
        max-width: 40px;
        max-height: 40px;
      }
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        border-radius: 50%;
        left: -21px;
        top: 23px;
        background-color: rgb(194, 144, 50);
      }
    }
    &__Index {
      position: relative;
      width: 100px;
      background: #15375c;
      color: white;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      height: 70px;
      &--Line1 {
        width: 20px;
        height: calc(100% + 20px);
        left: 10px;
        top: -10px;
        position: absolute;
        -webkit-box-shadow: inset -10px -1px 15px -9px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset -10px -1px 15px -9px rgba(0, 0, 0, 0.4);
        box-shadow: inset -10px -1px 15px -9px rgba(0, 0, 0, 0.4);
      }
      &--Line2 {
        width: 20px;
        height: calc(100% + 20px);
        top: -10px;
        left: 30px;
        position: absolute;
        background: white;
      }
      &--Text {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 15px;
      }
      &--Line3 {
        width: 20px;
        height: calc(100% + 20px);
        left: 50px;
        top: -10px;
        position: absolute;
        -webkit-box-shadow: inset 10px -1px 15px -9px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset 10px -1px 15px -9px rgba(0, 0, 0, 0.4);
        box-shadow: inset 10px -1px 15px -9px rgba(0, 0, 0, 0.4);
      }
    }
    &__Content {
      border: 2px solid #15375c;

      flex: 1;
      flex-shrink: 0;
      flex-grow: 1;
      background: #15375c;
      border-radius: 0 0 4px 4px;
      &--Title {
        height: 40px;
        padding: 10px;

        color: white;
        background: #15375c;
      }
      &--Inner {
        border-radius: 35px 0 4px 4px;
        padding: 10px;
        background: white;
        &__Text {
          min-height: 200px;
        }
      }
      &--Documents {
        &__Item {
          border: 2px solid #15375c;
          border-radius: 4px;
          padding: 4px;
          margin-top: 5px;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
}
.react-loading {
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  .Timeline {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .Timeline {
    &__Item {
      flex-direction: column;
      &__Duration {
        left: 5px;
        min-width: 320px;
        text-align: center;
      }
      &__Index {
        display: none;
      }
      &__Icon {
        width: 70px;
        border-radius: 50%;
        padding-left: 0;
      }
      &__Content {
        margin-left: 15px;
        &--Inner {
          border-radius: 0;
        }
      }
    }
  }
}

@keyframes changeHeight {
  from {
    height: 100%;
  }
  to {
    height: 150px;
  }
}

.WorkflowTimelineContainer {
  #noanim-tab-example-tab-1,
  #noanim-tab-example-tab-2 {
    text-transform: initial;
  }

  .cate-select1 {
    width: 100px !important;
    height: 30px !important;
    box-shadow: none;
  }
  .draft-revision {
    display: flex;
    width: fit-content;
    .dropdown-menu.show {
      left: 5px !important;
      bottom: -30px !important;
    }
  }
  margin: 0 auto;
  background: white;
  padding-bottom: 200px;
  .WorkflowTimeline {
    .data-reuse-container {
      display: flex;
      margin-right: 10px;
      margin-bottom: 5px;
      min-width: fit-content;
    }
    .drafts-container {
      .dropdown-menu {
        // top: unset !important;
        // bottom: 100% !important;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding: 5px;
          }
        }
      }
      display: flex;
      flex-wrap: wrap;
      table {
        .shareColumn,
        .deleteColumn,
        .revisions {
          width: fit-content;
          max-width: fit-content;
          padding: 3px;
          select {
            width: fit-content !important;
          }
        }
        .column-shareColumn,
        .column-deleteColumn,
        .column-revisions {
          width: fit-content;
          padding: 10px !important;
          text-align: center !important;
        }
      }
    }
    margin-top: 20px;
    width: 100%;
    /* height: 140px; */
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    &__Nontext {
      width: 30%;
      display: flex;
      height: 140px;
      &__Img {
        text-align: center;
        width: 100px;
        position: relative;
        img {
          max-width: 90px;
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
        }
      }
      &__Block {
        position: relative;
        /* width: 300px; */
        width: 75%;
        width: calc(100% - 100px);
        &__Square {
          height: 100%;
          /* width: 200px; */
          width: 66.66%;
          width: calc(100% - 100px);
          position: absolute;
          background: rgb(21, 55, 92);
          text-align: right;
          padding-right: 11px;
          line-height: 140px;
          font-size: 60px;
          color: white;
          &__Square {
            position: absolute;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform-origin: 0 0;
            top: -33px;
            left: 93px;
            background: #fff;
            padding: 2px 35px;
            width: 210px;
            color: #000;
            font-size: 11px;
            text-align: center;
            height: 44px;
            line-height: 12px;
            display: table;
            span {
              display: table-cell;
              vertical-align: middle;
              font-weight: bold;
            }
          }
          &__Elip {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            max-width: 125px;
            width: 62.5%;
            width: 125px;

            height: 165px;
            background: black;
            opacity: 23.5;
            -webkit-filter: blur(10px);
            -webkit-transform: scale(1, 0.2);
            top: -10px;
            left: 9px;
            transform: rotateY(-60deg);
          }
        }

        &__Triangle {
          width: 0;
          height: 0;
          border-top: 70px solid transparent;
          /* border-left: 50px solid red; */
          border-left: 50px solid rgb(21, 55, 92);
          border-bottom: 70px solid transparent;
          position: absolute;
          z-index: 1;
          left: 200px;
          left: calc(100% - 100px);
        }
        &__Triangle2 {
          width: 100px;
          height: 140px;
          background: linear-gradient(
            to bottom right,
            #007bff 50%,
            rgba(0, 0, 0, 0.5) 50%,
            transparent 54%
          );
          position: absolute;
          z-index: 0;
          left: 200px;
          left: calc(100% - 100px);
          top: 0;
          &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 140px solid white;
            border-right: 100px solid transparent;
            position: absolute;
            z-index: 1;
            left: 0;
          }
        }
      }
    }

    &__Text {
      width: 60%;
      padding: 20px;
      padding-right: 40px;

      -webkit-box-shadow: inset -10px 8px 5px -3px rgba(0, 0, 0, 0.5);
      box-shadow: inset -10px 8px 5px -3px rgba(0, 0, 0, 0.5);
      position: relative;
      &::before {
        display: block;
        content: "";

        width: 40px;
        height: 100%;
        background: white;
        position: absolute;
        right: 0;
        top: 0;
      }
      &::after {
        display: block;
        content: "";

        width: 10px;
        height: 100%;
        background: rgb(21, 55, 92);
        position: absolute;
        right: 0;
        top: 0;
      }
      &__BtnExpand {
        width: 25px;
        position: absolute;
        right: 40px;
        bottom: 0;
        img {
          max-width: 25px;
          opacity: 0.5;
          cursor: pointer;
        }
      }
      &__Content {
        &--Expand {
          display: block;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: max-height 1s ease-in;
          max-height: unset;
        }
        &--Collapse {
          display: block;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: max-height 1s ease-out;
          max-height: 130px;
        }
        &--Documents {
          margin-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
          &__Item {
            color: white;
            text-decoration: none;
            padding: 5px;
            margin-right: 4px;
            margin-bottom: 4px;
            display: inline-block;
            -webkit-box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
            box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
            background-color: rgb(21, 55, 92);
            &:hover {
              box-shadow: 7px 11px 4px -5px rgba(0, 0, 0, 0.75);
              -webkit-box-shadow: 7px 11px 4px -5px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 7px 11px 4px -5px rgba(0, 0, 0, 0.75);
            }
          }
        }
      }

      &__Info {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 40px;
        font-size: 26px;
        top: 20px;
        cursor: pointer;
        z-index: 99;
      }
    }
  }

  $order0: #15375c;
  $order1: rgb(21, 55, 92);
  $order2: rgb(21, 55, 92);
  $order3: rgb(21, 55, 92);
  $order4: rgb(21, 55, 92);
  $order5: rgb(21, 55, 92);
  $order6: rgb(21, 55, 92);
  $orderEnd: rgb(21, 55, 92);

  .WorkflowTimeline {
    &--0,
    &--start {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order0;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order0;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order0;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: #15375c;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--1 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order1;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order1;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order1;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order1;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--2 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order2;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order2;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order2;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order2;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--3 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order3;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order3;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order3;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order3;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--4 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order4;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order4;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order4;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order4;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--5 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order5;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order5;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order5;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order5;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--6 {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $order6;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $order6;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $order6;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $order6;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
    &--end {
      .WorkflowTimeline__Nontext__Block__Square,
      .WorkflowTimeline__Text::after {
        background: $orderEnd;
      }
      .WorkflowTimeline__Nontext__Block__Triangle {
        border-left-color: $orderEnd;
      }
      .WorkflowTimeline__Text__Content--Documents__Item {
        background: $orderEnd;
      }
      .btn-WorkflowTimeline__Text__Content--Documents__Item {
        background: $orderEnd;
        color: #fff;
        padding: 5px;
        margin: 0px 10px 4px 0px;
        box-shadow: 8px 7px 9px -5px rgba(0, 0, 0, 0.75);
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-radius: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .WorkflowTimelineContainer {
    width: 100%;
    padding-top: 20px;
  }
}

//for pc
@media only screen and (min-width: 1024px) {
  .WorkflowTimelineContainer .WorkflowTimeline__Nontext__Block {
    margin-left: 0px;
  }
  .WorkflowTimelineContainer .WorkflowTimeline__Text {
    width: 70%;
    margin-left: -100px;
  }
}

// for Ipad
@media only screen and (max-width: 1000px) {
  .WorkflowTimelineContainer {
    width: 100%;
    .WorkflowTimeline {
      &__Nontext {
        &__Block {
          width: calc(100% - 50px);
          &__Square {
            width: calc(100% - 50px);
            font-size: 40px;
            padding-right: 0px;

            &__Square {
              top: -33px;
              left: 93px;
              height: 44px;
            }
            &__Elip {
              width: 110px;
              left: 15px;
            }
          }
          &__Triangle {
            left: calc(100% - 50px);
          }
          &__Triangle2 {
            left: calc(100% - 50px);
            top: 0;
            &::before {
              left: 0;
            }
          }
        }
      }
      &__Text {
        width: 70%;
        padding-top: 40px;
        padding-top: 15px;
      }
    }
  }
}

@media (min-width: 751px) {
  .mobile-expand,
  .WorkflowTimeline__Mobile__Nontext {
    display: none;
  }
  .my-workflow-quick-button {
    margin-bottom: -45px;
    margin-right: 40px;
  }
}

@media (min-width: 1024px) {
  .my-workflow-quick-button {
    margin-bottom: -45px;
    margin-right: 100px;
  }
}

@media (min-width: 1024px) {
  .my-workflow-quick-button {
    margin-bottom: -25px;
    margin-right: 140px;
  }
}

@media (max-width: 750px) {
  .WorkflowTimeline .WorkflowTimeline__Nontext {
    display: none;
  }
  .WorkflowTimelineContainer .WorkflowTimeline__Text {
    width: 100%;
    padding-top: 45px;
    box-shadow: none;
  }

  .WorkflowTimeline__Text__Content.WorkflowTimeline__Text__Content--Collapse {
    display: block;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 1s ease-out;
    max-height: 20px;
  }

  .WorkflowTimeline__Mobile__Nontext {
    background-color: #15375c;
    color: white;
    width: 100%;
    margin-left: 10px;
    position: absolute;
    padding: 10px;
  }

  .WorkflowTimelineContainer .WorkflowTimeline__Text__BtnExpand {
    top: 5px;
    width: 25px;
    position: absolute;
    right: 40px;
    bottom: 0;
    fill: white;
  }

  .WorkflowTimelineContainer .WorkflowTimeline__Text__BtnExpand img {
    opacity: 1;
    filter: invert(1);
  }
  .my-workflow-quick-button {
    margin-bottom: -45px;
    margin-top: 5px;
    margin-right: 15px;
  }
}

// for S5, X, Pixel and <
@media only screen and (max-width: 600px) {
  .WorkflowTimelineContainer {
    width: 100%;
    .WorkflowTimeline {
      &__Nontext {
        &__Img {
          display: none;
        }
        &__Block {
          width: 100%;
          &__Square {
            width: calc(100% - 50px);
            &--Order {
              position: absolute;
              z-index: 9999;
              padding-right: 0px;
              font-size: small;
            }
            &__Square {
              top: -33px;
              left: 42px;
              font-size: 11px;
              height: 25px;
            }
            &__Elip {
              width: 60px;
              left: 0px;
            }
          }
          &__Triangle {
            left: calc(100% - 50px);
            border-left: 25px solid rgb(21, 55, 92);
          }
          &__Triangle2 {
            left: calc(100% - 50px);
            width: 50px;
            top: 0;
            background: linear-gradient(
              to bottom right,
              #007bff 50%,
              rgba(0, 0, 0, 0.5) 50%,
              transparent 58%
            );
            &::before {
              left: 0;
              border-right: 50px solid transparent;
            }
          }
        }
      }

      &__Text {
        padding-left: 10px;
        padding-top: 45px;
        &::before {
          width: 15px;
        }
        &__Info {
          top: 45px;
        }
      }
    }
  }
}

//for Iphone 5 and <
@media only screen and (max-width: 322px) {
  .WorkflowTimelineContainer {
    .WorkflowTimeline {
      &__Nontext {
        &__Img {
          display: none;
        }
        &__Block {
          width: 100%;
          &__Square {
            width: calc(100% - 50px);
            font-size: 30px;
            &--Order {
              position: absolute;
              right: 0px;
              z-index: 9999;
              right: -10px;
            }
            &__Square {
            }
            &__Elip {
              width: 60px;
              left: 0px;
            }
          }
          &__Triangle {
            left: calc(100% - 50px);
            border-left: 25px solid rgb(21, 55, 92);
          }
          &__Triangle2 {
            left: calc(100% - 50px);
            width: 50px;
            top: 0;
            background: linear-gradient(
              to bottom right,
              #007bff 50%,
              rgba(0, 0, 0, 0.5) 50%,
              transparent 58%
            );
            &::before {
              left: 0;
              border-right: 50px solid transparent;
            }
          }
        }
      }

      &__Text {
        padding-left: 10px;
      }
    }
  }
}
