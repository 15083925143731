.partnerList {
  .row {
    margin: 0;
    .partner, .arrows {
      text-align: center;
      cursor: pointer;
      height: 125px;
      padding: 0px;
    }
    
    .arrows {
        position: absolute;
        margin-top: -6rem;
        right: 11%;
        margin: -6rem 0 0 0;
    }

    .arrows.hidden {
      visibility: hidden;
      display: block !important;
    }
    .arrow {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e8e8e8;
        margin-right: 5px;
        font-size: 1.7rem;
        text-align: center;
        color: #bbb;
        cursor: pointer;
        line-height: 24px;
        vertical-align: text-bottom;
    }

    .partner {
      display: none;
      padding: 0 15px;

      img {
        width: 75px;
        margin: 2% auto;
      }

      a {
        line-height: 125px;
        width: 100%;
        height: 100%;
        display: inline-block;
        border: 2px solid #e1e8ee;
        border-radius: 2px;
        background-color: #fff;
       
        &:hover {
            border-color: #d1dce5;
        }
      }
      
      
    }

    .partner.visible {
      display: block;
    }
  }
}

@media (max-width: 991px) {
  .partnerList {
    .row {
      .partner, .arrows {
		margin: 0;
        margin-top: 20px;
      }
    }
  }
}