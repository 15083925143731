.Navbar {
  position: relative;
  z-index: 2;
  //clear: right;

  @media (max-width: 480px){ 
    a.hide-mobile {
      display: none;
    }
    .rightmenu {
      width: 200px;
    }
  }

  @media (min-width: 481px){ 
    a.hide-mobile {
      display: inline-block;
    }
    .leftmenu {
      margin-top: 10px;
      position: absolute;
    //  float: left;
    }
    a.btn.btn-secondary.mobile-only {
      display: none;
    }
  }
  
  .leftmenu {
    border-radius: 3px;
    background-color: rgba(1, 1, 40, 0.9);
    height: auto;
    z-index: 9999;
    display: none;
    padding: 1% 1% 1% 1%;
    width: 200px;
    left: 0;
    top: 35px;
    text-align: left;
    a {
      width: 100%;
      margin-left: 0px !important;
      text-align: left;
      margin-bottom: 2px;
    }

  }

  .btn.btn-primary {
    background-color: #c29032;
    border-color: #c29032;
  }

  .btn.btn-primary:hover {
    background-color: #c29032;
    border-color: #c29032;
  }

  .btn.btn-secondary {
    background-color: transparent;
    border: none;
    opacity: 0.8;
  }

  .btn:hover,
  .btn.btn-secondary:hover {
    background-color: #c29032;
    border-color: #c29032;
  }

  a {
    margin-left: 0.2rem;
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
    font-weight: 500;

    @media (max-width: 480px) {
      margin-left: 0;
    }

    &:hover {
      text-decoration: underline;
      color: #fff;
      opacity: 1;
    }

    &.btn-primary,
    &.btn-secondary {
      margin-left: 0.5rem;
      opacity: 1;

      @media (max-width: 480px) {
        border-radius: 3px !important;
        border-color: hsla(0, 0%, 100%, 0.6) !important;
        border-bottom-width: 1px !important;
        border-bottom-style: solid !important;

        &:hover {
          color: #fff !important;
          opacity: 1 !important;
        }
      }
    }

    &.btn:focus,
    &.btn:hover {
      color: #fff !important;
    }

    &.profile {
      display: inline-block;
      margin-top: -1rem;
      text-decoration: none !important;
      font-size: 0.9rem;

      @media (max-width: 480px) {
        float: left;
        width: 100%;
        padding: 9px;
        width: calc(100% - 2px);
        text-align: left;
        font-size: 14px;
        display: block;
        margin-top: 0;

        i {
          margin-left: 10px;
        }
      }

      i,
      img {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-right: 0.2rem;
        line-height: 2rem;
        font-size: 1rem;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50%;
      }
      i {
        background: none;
      }
    }
  }

  span {
    padding: 0 0 0 0.7rem;
    color: #fff;
    @media (max-width: 480px) {
      display: block;
    }
    a {
      color: #fff;
    }
  }

  
  .dropdown-menu {
    display: block;
    top: 0;
    right: 0;
    border-radius: 3px;
    background-color: rgba(1, 1, 40, 0.9);
    height: auto;
    z-index: 9999;
    padding: 1% 1% 1% 1%;
    width: 200px;
    top: 40px;
    text-align: left;
 
    
    @media (max-width: 480px) {
      padding: 0;
      margin: 0;
      border-width: 0;
      background: transparent;
      position: relative;
      width: 100%;
      top: 0;
    }

    li {
      @media (max-width: 480px) {
        border-radius: 3px !important;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-color: hsla(0, 0%, 100%, 0.6);
      }

      a {
          padding: 9px 9px 9px 20px !important;
          color: #fff;

          &:hover {
            color: #c29032;
          }
        
      }
    }
  }

  .boron-modal {
    & > div:first-child {
      span,
      a {
        color: inherit;
      }
      .form-actions {
        padding-top: 0;
      }
      .other-actions {
        float: left;
        width: 100%;
      }

      p {
        margin-bottom: 3rem;
        opacity: 0.8;
      }

      .alert {
        text-align: left;
      }
    }
  }
  .cookie-modal {
    box-shadow: 6px 5px 23px -5px #9ea6ad;
  }
}

.App {
  .registration-modal {
    box-shadow: 6px 5px 23px -5px #9ea6ad;
    z-index: 1041 !important;
    > div {
      &:first-child {
        padding: 8% 10%;
        p {
          width: 80%;
          margin: 0 auto 3rem;
          &.btn-nostyle {
            border: none !important;
            background-color: #fff !important;
            font-size: 10px;
            &:hover {
              color: #000000;
            }
          }
        }
        .form-group {
          .form-control {
            font-size: 11px !important;
            &::-webkit-input-placeholder {
              color: #43484d;
            }
            &::-moz-placeholder {
              color: #43484d;
            }
            &:-ms-input-placeholder {
              color: #43484d;
            }
            &:-moz-placeholder {
              color: #43484d;
            }
          }
        }
      }
    }
    .form-group,
    .form-actions {
      margin-bottom: 0;
      &.col-sm-6,
      &.col-xs-12 {
        padding: 5px !important;
      }
    }
  }
}

/* Shared */

.socialLoginBtn {
  padding: 0 25%;
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0 0px 12px 0 !important;
  border: none;
  text-align: center;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  width: 100% !important;
  height: 43px;
  margin-left: 0px;
}

/* Facebook */

.socialLoginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;

  &:hover,
  &:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
  }
}

/* Google */

.socialLoginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;

  &:hover,
  &:focus {
    background: #e74b37;
  }
}

/* Linkedin */

.socialLoginBtn--linkedin {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #0077b5;
  &:hover,
  &:focus {
    background: #0077b5;
  }
}

.socialLoginBtn i {
  font-size: 17px;
  padding: 10px;
}

.icon-div {
  width: 29px;
  float: left;
  border-right: 1px solid #364e92;
}

.icon-text-div {
  text-align: left;
  margin-left: 17%;
}

@media (max-width: 480px) {
  .socialLoginBtn {
    padding: 0px;
  }

  .icon-text-div {
    margin-left: 0px;
  }

  .App .registration-modal {
    height: 570px;
    overflow-x: auto;
  }

  .App .Navbar .btn-secondary {
    margin-top: 0px;
  }

  .Navbar {
    display:none;
  }

  a.btn.btn-secondary.mobile-only {
    display: inline-block;;
  }
}

@media (max-width: 770px) {
  .Navbar {
    float: none;
    clear: right;
	
  }
}

@media (max-width: 480px) {
  .Navbar {
    .only-desktop {
      display: none !important;
    }

    

  }
}
@media (min-width: 481px) {
  .Navbar {
    span.rightmenu {
      display: block;
      position: relative;
      float: right;

      
    }
    
  }
@media (max-width: 730px) {
  .Navbar {
    span.rightmenu .btn {
      font-size: x-small;
    }
    span.rightmenu {
      top: 5px;
    }

  }
}
@media (max-width: 580px) {
  .Navbar {
    span.rightmenu .btn {
      font-size: xx-small;
    }

  }
}
  
}
