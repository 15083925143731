.shareddraftslist {
  margin-top: 2rem;
  font-size: 0.9rem;
  display: inline-block;
  width: 100%;
  ul {
    list-style: none;
    padding: 0;
  }
  > ul {
    padding: 0;
    > li {
      // &:nth-child(odd) {background: #ddd}
      > span.button,
      > button {
        float: right;
        margin-left: 10px;
      }
    }
  }

  .socialDropdown {
    li {
      border-bottom: 1px solid #e1e8ee;
      padding: 0.5rem 0;
      margin: 0;
      float: left;
    }
  }

  > ul > li img.icon-info {
    width: 40px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .form-modal {
    form {
      button {
        display: inline-block;
        &.add-email {
          display: none;
        }
      }
    }
  }
  .share-link {
    word-wrap: break-word;
  }

  select {
    display: inline-block;
    width: inherit;
    // margin-left: 1rem;
  }

  img.icon-info {
    width: 40px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .share-fields-box {
    height: 150px;
    overflow: auto;
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      border-top: 1px solid #eee;
    }
  }

  .ion-edit:hover {
    cursor: pointer;
  }

  table {
    thead {
      // border: 1px solid #bfbfbf;

      .column-draftname {
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-left-radius: 10px;
      }

      .column-catname {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
      }
    }

    tbody {
      border: 1px solid #e1e8ee;
      border-top: none;
      margin: 20px;
      border-radius: 3px;
    }

    th {
      padding: 20px 20px !important;
    }
    td {
      position: relative;
      padding: 10px 20px !important;
      text-align: left;
      border: none !important;

      a {
        color: #000000;
      }

      a:hover {
        color: #000000;
        text-decoration: none;
      }
    }

    th {
      background: #132d5e;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
    }

    tr {
      &:nth-child(2n + 1) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:nth-child(2n) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:last-child {
        border-bottom: none;
        .name {
          -webkit-border-bottom-left-radius: 10px;
          -moz-border-radius-bottomleft: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      .column-draftname {
        -webkit-border-top-left-radius: 0px;
        -moz-border-radius-topleft: 0px;
        border-top-left-radius: 0px;
      }
    }
  }

  .defaultMsg {
    padding: 25px;
    text-align: center;
    font-size: 16px;
  }

}

.inner-page-cont
{

}

.App {
  .container {
    height: initial;
  }
}
