.DocumentList {
  display: flex !important;
  justify-content: space-between;
  padding-left: 0;

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 1.6rem;
  }

  li img.icon-info {
    // width: 40px;
    margin-left: 40px;
    margin-right: 35px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .boron-modal {
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    // width: 600px !important;
    text-align: center;
    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
      i {
        margin-right: 0px;
        font-size: 34px;
      }
    }

    .draftName {
      margin-bottom: 10px;
    }

    button {
      margin-top: 30px;
    }

    .btn {
      margin-right: 2%;
      i {
        margin-right: 10px;
      }
    }
    .select-doc {
      .btn.btn-primary {
        margin-top: 0px;
        height: 55px;
        margin-left: 10px !important;
      }
    }
  }

  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 55px;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: 18px;
    background: #132d5e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn2 {
    background: #c29032 !important;
  }

  .btn3 {
    background: #535353 !important;
  }

  .btn4 {
    background: #e81e1e;
  }

  @media (max-width: 570px) {
    flex-direction: column;

    & ul {
      flex-direction: column;
      margin: 0 auto;
    }

    & ul li {
      width: 100%;
      margin: 0 auto;
    }
  }

  @media (max-width: 1025px) {
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    .boron-modal {
      width: 600px !important;
    }
  }

  ul {
    padding: 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;

    &:nth-child(3) {
      margin-left: 15%;
    }

    @media (max-width: 1026px) {
      &:nth-child(3) {
        margin-left: 0;
      }

      & {
        width: 100%;
      }

      & li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 20px;
      }

      & .actions {
        order: 2;
      }
    }

    li {
      list-style: none;
      width: 100%;
      border-bottom: 1px solid #e1e8ee;
      padding: 0.5rem 0;
      margin: 0;
      float: left;

      a,
      a:hover {
        display: inline-block;
        position: relative;
        font-size: 0.9rem;
        text-decoration: none;
        color: #6c6c72;
        margin-top: 12px;

        i.paper-icon {
          display: inline-block;
          color: #d6dade;
          margin-right: 1rem;
          font-size: 4rem;
          line-height: 4rem;
        }
      }

      .actions {
        min-width: 0;
        @media (max-width: 767px) {
          min-width: 131px;
        }
        // float: right;
        .btn.btn-link {
          background-color: transparent;
          color: #132d5e;
          border: none;
        }
        a {
          margin-top: 0;
        }
        i {
          font-size: 1.4rem;
          margin-right: 10px;

          @media (max-width: 767px) {
            margin-right: 0;
          }
        }
        .btn {
          // padding: 9px 15px !important;
        }
        span {
          vertical-align: text-top;
          line-height: 1rem;
        }
      }

      .col-xs-5 .btn.btn-details-doc {
        @media (min-width: 767px) {
          max-width: 80%;
        }
      }
      .document-names {
        min-width: 0;
        text-overflow: ellipsis;
        @media (max-width: 767px) {
          min-width: 131px;
          float: none;
        }

        .btn-make-doc {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          a {
            position: unset;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.title {
              text-overflow: ellipsis !important;
              z-index: 1;
            }
          }
          a.title:hover {
            color: white;
          }
        }
        .btn-make-doc:hover {
          color: white;
        }

        float: left;
        .btn.btn-link {
          background-color: transparent;
          color: #132d5e;
          border: none;
        }
        a {
          margin-top: 0;
        }
        i {
          font-size: 1.4rem;
          margin-right: 10px;
          margin-top: 0;

          @media (max-width: 767px) {
            margin-right: 0;
          }
        }
      }

      a:hover {
        color: darken(#6c6c72, 5%);

        i.paper-icon {
          color: darken(#d6dade, 5%);
        }
      }

      span.title {
        bottom: 1rem;
      }

      .btn:hover {
        border: 1px solid darken(#e1e8ee, 5%);
      }
    }
  }
  .listAutoSuggestion {
    .css-12jo7m5 {
      max-height: 35px !important;
      white-space: normal !important;
    }
    .btn-primary {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}

.document-details-actions {
  display: flex;
  div {
    text-align: center;
    width: 50%;
    i.fa-info-circle {
      vertical-align: middle;
      font-size: 27px !important;
      color: #333 !important;
      margin-top: 4px;
    }
  }
  .socialShareBlock {
    border: "3px solid blue";
    width: 30px;

    img.icon-info {
      margin-left: 4px !important;
      margin-right: 4px !important;
    }
  }
}

.documentlist-cont {
  width: 100%;
  float: left;
  padding-top: 40px;
  padding-bottom: 140px;
  background: #ffffff !important;

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 1.6rem;
  }

  .btn.btn-make-doc {
    border: 2px solid #15375c;
    border-radius: 3px;
    min-width: 400px;
    text-align: left;
    padding: 4px 15px;
  }

  .btn.btn-make-doc:hover {
    background-color: #15375c;
    border: 2px solid #15375c;
    border-radius: 3px;
    min-width: 400px;
    span,
    i,
    a {
      color: white;
    }
  }

  @media (max-width: 1024px) {
    .DocumentList.col-md-10 {
      width: 100%;
      padding-right: 0;
    }
  }

  @media (max-width: 767px) {
    .DocumentList {
      .doc-name-cont {
        .far.fa-file-alt.file-icon {
          display: none;
        }

        .btn.btn-make-doc {
          background-color: white;
          color: #15375c;
          border-radius: 3px;
          padding-right: 2px;
          border: 2px solid #15375c;
          min-width: 175px !important;
          // max-width: 175px;
          text-align: left !important;
          display: flex;
          justify-content: center;
        }
        .btn.btn-make-doc:hover {
          background-color: #15375c;
          border: 2px solid #15375c;
          border-radius: 3px;
          span,
          i,
          a {
            color: white;
          }
        }
        .title {
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          float: left;
          font-size: small;
          color: #15375c;
        }
        .icon-info {
          margin-left: 15px !important;
          margin-right: 15px !important;
        }
        .document-details-name {
          padding-left: 4px;
          padding-right: 0;
          .btn-details-doc {
            padding-left: 0 !important;
            padding-right: 0 !important;
            .details-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: small;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
        .details-text {
          font-size: small;
        }
      }
    }

    .document-details1 {
      width: 100% !important;
      margin-top: 25px !important;
      padding: 15px !important;

      td {
        font-size: 14px !important;
      }

      .document-txt-left {
        width: 115px !important;
      }

      .document-details-right {
        float: left !important;
      }

      .document-badge {
        height: 40px !important;
        line-height: 20px !important;
      }

      .document-listoption {
        .icon-info {
          // width: 40px;
          margin-left: 15px !important;
          margin-right: 15px !important;
        }

        .title {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 150px !important;
          font-weight: 600;
          font-size: small;
          color: #15375c;
        }
      }
    }

    .actions,
    .document-names {
      margin-top: 10px;
    }

    .documentlist-cont {
      .document-txt-left {
        width: 130px !important;
      }
    }
    .DocumentList ul li {
      display: block !important;
    }
  }

  .doc-name-cont {
    width: 100%;
    float: left;
    cursor: pointer;
  }

  .document-details1 {
    width: 100%;
    background: #f5f5f5;
    float: left;
    padding: 32px 38px;
    margin-top: 48px;
    border-radius: 3px;
    border: #dadada solid 1px;
    margin-bottom: 43px;
    padding-bottom: 22px;
    position: relative;

    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.4);
      color: #e81e1e;
    }
    .document-details-right {
      width: auto;
      float: right;
      margin-top: 34px;

      .document-badge {
        width: 130px;
        height: 60px;
        background: #c29032;
        border-radius: 3px;
        border: #cdcfcc solid 1px;
        font-size: 16px;
        color: #ffffff;
        line-height: 40px;
      }
    }
    .document-details-left {
      width: 375px;
      float: left;

      td {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        color: #000000;
      }

      .document-txt-left {
        width: 182px;
      }

      .spe2 {
        width: 35px;
      }
    }
  }

  .document-listoption {
    i {
      font-size: 27px;
      margin-right: 10px;
      float: left;
      margin-top: 0px;
    }

    .actions {
      i {
        float: none;
        margin-top: 0px;
        font-size: 14px;
        color: #7fc757;
      }

      i.fa.fa-chevron-down {
        color: white;
        padding-left: 13px;
        @media (min-width: 767px) {
          vertical-align: text-bottom;
        }
      }

      .details-icon {
        border-left: 2px solid white;
      }

      .details-text {
        padding-right: 10px;
        font-weight: 500;
      }

      .btn.btn-details-doc {
        background-color: #15375c;
        color: white;
        border-radius: 3px;
        //padding-right: 5px;
        // float: right;
        border: 2px solid #15375c;
        display: flex;
        justify-content: center;
		font-weight: 500;
		font-size: small;
      }

      .free-txt {
        font-size: 16px;
        color: #7fc757;
        line-height: 24px;
      }
    }

    .document-names {
      width: 100% !important;
      .btn.btn-make-doc {
        width: 100%;
        margin-bottom: 10px;
      }
      i {
        font-size: 27px;
        margin-right: 10px;
        float: left;
        margin-top: 0;
        color: #15375c;
      }
    }

    .title {
      font-size: 18px;
      color: #15375c;
      font-weight: 600;
    }

    .maak-btn {
      background: #f2f2f2;
      border: #b9b9b9 solid 1px;
      border-radius: 3px;
      color: #222222;
    }

    .maak-btn::hover {
      i {
        color: #ffffff;
      }
    }
  }
}
