.PageSize {
  text-align: right;

  label {
    font-weight: 600;
  }

  select {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #a7a7a7;
  }
}
