[class*="Page"] {
  height: 100%;

  .content {
    padding: 1.7rem 0 5rem 0;

    @media (max-width: 1400px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .inner-page-cont {
    background: #f9f9f9;
    @media (max-width: 768px) {
      .container {
        padding-top: 20px;
      }
    }
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 5rem;
    margin-bottom: 4rem;
    text-align: center;
    font-size: 200%;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #43484d;
  }
  button {
    i {
      margin-right: 0;
      font-size: 20px;
      vertical-align: middle;
    }
    span {
      display: none;
      line-height: 20px;
      vertical-align: middle;
    }

    &:hover {
      span {
        display: inline;
      }
      i[class*="ion"] {
        margin-right: 10px;
      }
    }
  }

  .tiny-mce {
    button {
      margin-top: 0px !important;
      span {
        display: inline;
      }
      i {
        vertical-align: inherit;
      }
    }
  }
}

.HomePage {
  .content {
    background-color: #f9f9f9;
    padding: 0.1rem 0 0;
    text-align: center;

    .categoryList {
      padding: 0 2.2rem;
    }

    .moreCategories {
      // position: relative;
      // display: inline-block;
      // margin: 1rem auto;
      // padding: 18px 22px;
      // border: 2px solid #132d5e;
      // border-radius: 3px;
      // color: #132d5e;
      // font-size: 1rem;
      // font-weight: 500;
      height: 60px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: 18px;
      background: #132e5e;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
      margin-top: 44px;
      &:hover {
        border-color: darken(#132d5e, 5%);
        color: #fff;
      }
    }
  }

  .container-row {
    margin: 0 auto;
  }
}

.DocumentListPage {
  .content {
    padding-top: 3rem;
  }
  > .container-row {
    padding-left: 15px !important;
    padding-right: 15px !important;

    @media (min-width: 1400px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .PageSorter {
    display: flex;
    justify-content: space-between;
  }
}

.DocumentFormPage {
  .content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.RequiredOptionsPage {
  .content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ArticlePage {
  .content {
    padding: 0;

    ul li::before {
      position: absolute;  
      
      margin: 8px 0 0 -40px;    
      vertical-align: middle;
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #43484d;
      content: "";
     
    }

    ul {
      list-style: none;
    }

    ol {
      margin-left: 10px;
    }
    
    @media (min-width: 1025px) {
      .col-sm-9 {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
      }
      .article.col-sm-9 {
        padding-left: 20px;
        padding-right: 20px;
      }

    }
    @media (max-width: 1024px) {
      .col-sm-9 {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px;
      }
      .text-right {
        text-align: left;
      }
    }
    .article {
      margin-top: 3rem;
      margin-bottom: 2rem;
      padding-top: 0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      .text-right {
        margin: 0 0 30px;
      }

      p {
        max-width: 70ch;
        text-align: left;
        font-size: small;
      }

      h1 {
        &.article-title {
          padding-bottom: 1rem;
          border-bottom: 2px solid #f0f0f0;
        }

        @media (max-width: 480px) {
          font-size: 180%;
        }
      }

      h2 {
        max-width: 80ch;
        margin-top: 3rem;
        margin-bottom: 2rem;
        text-align: left;
        line-height: 130%;

        @media (max-width: 480px) {
          font-size: 140%;
          margin-top: 2rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  @media (min-width: 1401px) {
    .content {
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ads {
    display: block;
    padding: 20px 20px 40px;
  }
}

.MaintenancePage {
  margin-top: 25px;
  h1 {
    font-size: 50px;
    text-align: center;
  }
  article {
    display: block;
    text-align: center;
    width: 650px;
    margin: 0 auto;
  }
  a {
    color: #dc8100;
    text-decoration: none;
  }
  a:hover {
    color: #333;
    text-decoration: none;
  }
  figure {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
  }
  .maintenance_img {
    width: 500px;
  }
}

.Profilepage {
  .inner-page-cont {
    background: #f9f9f9;

    .form-group {
      width: 100%;
      float: left;
    }
    .form-control {
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 3px !important;
      height: 60px;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    .image-container {
      width: 200px;
      height: 200px;
      margin: 20px 0;
      position: relative;

      .crop-image-btn {
        position: absolute;
        left: 200px;
        top: 0;
        border-radius: 30px;
        transform: translate(-50%, -50%);
        z-index: 2;
      }

      .dropzone {
        background-color: #fff;
        font-weight: 300;
        border: 2px dotted #d2d2d2;
        color: #a9a9a9;
        text-align: center;
        line-height: 17rem;
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        padding: 20px;

        img {
          width: auto;
          vertical-align: top;
          max-width: 210px;
          max-height: 160px;
          margin-top: 0px;
        }
      }
    }

    .form-control:focus {
      border-color: #4c7cff;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }

    .btn1 {
      height: 60px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: 18px;
      background: #132d5e;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
    }
    .btn2 {
      height: 60px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: 18px;
      background: #e81e1e;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
    }

    .opslann-btn1 {
      width: 210px;
    }

    .sluit-btn1 {
      width: 151px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .btn-block {
      width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    // profile  page  start

    .opslann-btn1 {
      width: 160px !important;
      padding: 0px !important;
    }

    .sluit-btn1 {
      width: 151px;
      padding: 0px !important;
    }

    .btn1,
    .btn2 {
      padding: 0px !important;
      padding-left: 20px !important;
      padding-right: 25px !important;
      line-height: 35px !important;
    }

    .UserForm {
      margin-top: 30px;
    }

    // profile  page  end
  }
}

.Draftpage {
  .cate-filter1 {
    // .form-control {
    //   width: auto;
    //   box-shadow: none;
    // }

    // .cate-name {
    //   width: 212px;
    // }
  }

  select {
    display: inline-block;
    width: inherit;
    // margin-left: 1rem;
    // width: 212px !important;
  }
  @media (max-width: 767px) {
    // .cate-name {
    //   width: 150px !important;
    // }
  }
}

.downloadpage {
}

.custom-progressbar .progress {
  height: 16px;
}
.custom-progressbar .progress-bar {
  font-weight: bold;
}

.react-collapsible-custom-theme {
  width: 100%;
  table {
    td:first-child {
      span:first-child {
        // width: 20px;
        float: left;
        margin-top: 5px;
      }
    }

    .edit-icon {
      width: 20px;
      float: left;
      margin-right: 10px;
      a.edit {
        background: #132d5e;
        display: inline-block;
        width: 20px;
        height: 20px;
        color: #ffffff !important;
        margin-top: 6px;
        i {
          font-size: 12px;
          display: block;
          text-align: center;
          padding: 4px;
        }
      }
    }

    .edit-text {
      line-height: 33px;
    }

    width: 100%;
    .deleteColumn {
      // width: 250px;
    }
    .expanded-row {
      .child-cell {
        float: left;
        width: 100%;
        .child-label {
          font-weight: bold;
          width: 50%;
          float: left;
        }
        .child-content {
          width: 50%;
          float: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .downloadpage {
    .cate-select {
      width: 140px !important;
    }
  }

  .Draftpage {
    .cate-select {
      width: 140px !important;
    }

    .cate-select1 {
      margin-left: 0px !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .edit-text {
    line-height: 28px !important;
    width: 70%;
    float: left;
  }

  .child-cell .child-label {
    font-weight: 700;
    width: 50%;
    float: left;
    line-height: 30px;
  }

  .set-reminder {
    margin-top: 0px !important;
  }
}

.setting-page {
  .setting-left {
    .form-actions {
      margin-top: 30px;
    }

    .form-group {
      width: 100%;
      float: left;
      margin-bottom: 8px;
    }

    .form-control {
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 3px !important;
      height: 60px;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    .text-area-setting1 {
      height: 110px;
    }

    .image-container {
      width: 300px;
      height: fit-content;
      margin: 20px 0;
      position: relative;

      .crop-image-btn {
        position: absolute;
        left: 300px;
        top: 0;
        border-radius: 30px;
        transform: translate(-50%, -50%);
        z-index: 2;
        line-height: 20px;
      }

      .dropzone {
        background-color: #fff;
        font-weight: 300;
        border: 2px dotted #d2d2d2;
        color: #a9a9a9;
        text-align: center;
        line-height: 17rem;
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        padding: 20px;
        height: 170px;

        img {
          width: auto;
          vertical-align: top;
          max-width: 210px;
          max-height: 160px;
          margin-top: 0px;
        }
      }
    }

    .btn1 {
      height: 60px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: 18px;
      background: #132d5e;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
    }
    .btn2 {
      height: 60px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: 18px;
      background: #e81e1e;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
    }

    .opslann-btn1 {
      width: 210px;
    }

    .sluit-btn1 {
      width: 151px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .setting-right {
    .DocumentFormPreview {
      padding: 2rem 1.25rem 0;
      font-size: 0.9rem;
      border: 1px solid #cecece;
      border-radius: 3px;
      background: #fff;
      margin-top: 60px;
    }
    .panel-body {
      background: #fff;
      margin: 1rem;
      padding: 2rem;
      box-shadow: 0 1px 15px 0 rgba(50, 50, 50, 0.1);
      position: relative;
    }
  }

  @media (max-width: 767px) {
    .setting-left {
      margin-top: 20px !important;
    }
    .btn1,
    .btn2 {
      padding: 0 !important;
      padding-left: 20px !important;
      padding-right: 25px !important;
      line-height: 35px !important;
    }

    .opslann-btn1 {
      width: 180px !important;
    }

    .DocumentFormPreview {
      margin-top: 20px !important;
    }

    .setting-left h4 {
      font-size: 22px !important;
    }
  }
}

.login-modal {
  .remember-me {
    justify-content: center;
    display: flex;
    p {
      margin-left: 5px;
      margin-bottom: 0px !important;
    }
  }
}

.draft-reuse-modal {
  width: 65% !important;
  height: auto;
  .modal-content-container {
    border: 2px solid #15375c;
    padding-bottom: 10px;
    border-radius: 3px;

    h4 {
      color: white;
      background-color: #15375c;
      margin: 0;
      padding: 5px;
    }
  }

  button {
    border: 2px solid #15375c;
    border-radius: 3px;
    color: #15375c;
    background: white;
    font-weight: 500;
  }

  .categories-container {
    max-height: 60vh;
    overflow-y: auto;
    // margin-top: 10px;
    padding: 0px 10px;
    text-align: left;
    button {
      width: 95%;
      text-align: left;
    }
    .child-accordion {
      margin-left: 20px;
    }
  }

  .docs-list-container {
    padding: 0px 20px;
    margin: 10px 0px 0px 0px;
    .list-group-item {
      border: 0px !important;
      padding: 5px 5px 5px 0px;
      display: flow-root;
      button {
        margin: 0 0 0 5px !important;
        width: auto;
        // float: right;
        background: #15375c;
        color: white;
        position: absolute;
        right: 5px;
      }
      .checked {
        background: #c29032 !important;
      }
    }
    input {
      margin-right: 5px;
      margin-top: 0;
    }
  }

  .selected-documents-container {
    max-height: 20vh;
    overflow-y: auto;
    .doc-badges {
      margin-right: 5px;
      .ion-android-cancel {
        color: white !important;
      }
    }
    button {
      border: 2px solid #c29032;
      background-color: #c29032;
      color: white;
      margin-top: 10px !important;
      width: 98%;
      :focus {
        border: 2px solid #c29032;
      }
    }
    label {
      margin-top: 5px;
      width: auto !important;
      font-size: 0.9rem !important;
    }
  }
}

.draft-reuse-icon {
  i {
    font-size: 24px;
    color: #333;
  }
}
