@media (max-width: 768px) {
	/*
	 * Base structure
	 */
	// html,
	// body {
	// 	//height: 100%;
	// 	font-size: 13px;
	// 	color: #333;
	// }
	.App {
		.DocumentFormSteps {
			height: auto !important;
			ul {
				display: flex !important;
				.number {
					text-align: center !important;

				}
				.name{
					text-align: center !important;
					word-wrap: break-word !important;
				}
			}
		}
		.DocumentFormFields{
			height: auto !important;
		}
		.DocumentFormPreview{
			// display: none !important;
		}
		.boron-modal {
			width: 85% !important;
		}

		.counter {
			.row {
				padding-top: 45px;
			}
			.withDivider {
				&:after {
					right: 0;
				}

				&:nth-child(2) {
					&:after {
						border: none;
					}
				}
			}
		}

		.footer {
			.row {
				.infoContainer, .socialIcons, .logo {
					text-align: left;
				}
			}
		}
	}
}
