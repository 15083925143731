.DocumentFormSteps {
  .dummySteps{
    display: flex;
    justify-content: center;
    > div{
      display: flex;
      align-items: center;
    }
  }
}
