.boron-modal {
  overflow-y: unset;
  .modal-close-btn {
    width: 35px;
    position: absolute;
    right: -15px;
    top: -15px;
    background: #fff;
    height: 35px;
    border-radius: 50%;
    font-size: 34px;
    line-height: 32px;
    text-align: center;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    color: #e81e1e;
  }
}
.carousel-model{
  z-index: 1041 !important;
}

.CarouselContainer {
  width: 100%;
  .carousel {
    overflow: unset !important;
    .control-next.control-arrow::before {
	   border-left: 8px solid #000;
	}
	.control-prev.control-arrow::before {
	   border-right: 8px solid #000;
  }
    .control-dots {
      display: flex;
      justify-content: center;
      margin-top: 10px !important;
      bottom: -20px !important;
      .dot {
        margin: 4px;
        display: block;
        position: unset;
        background-color: white;
        opacity: 1;
        &.selected {
          background: #15375c;
        }
      }
    }
    .carousel-status {
      text-shadow: none;
      box-shadow: 0;
      left: 0 !important;
    }
    .slider-wrapper {
      .slider {
        border-radius: 6px;
        .slide {
          border-radius: 4px;
          padding: 20px;
          background: #fff;
          .slide-title {
            // width: 100% !important; 
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
          }
          .slider-title-eye{ 
            margin-left: 10px;
            font-size: 22px;
            position: absolute;
            left: 15px;
          }
        }
      }
    }
    .thumbs-wrapper.axis-vertical {
      display: none;
    }
  }
  .form-actions {
    button {
      margin-top: 4px;
      margin-bottom: 6px;
    }
  }
 
  .btn-carousel {
    border-radius: 3px;
	padding: 0 10px;
	background: #fff;
	border: 1px solid #d9d9d9;
	margin-bottom: 25px;
	width: 100%;
	color: #43484d;
	text-align: left;
	span {
	  display: inline-block;
	}
	i {
		position: relative;
		left: -11px;
		display: inline-block;
		background: #15375c;
		height: 100%;
		padding: 16px;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		color: white;
	  }
  }
  
  // .react-scrollbar-default {
  //   margin-right: 15px;
  //   margin-bottom: 10px;
  //   max-height: 400px;
  // }
}
