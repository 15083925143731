.doc-options-container {
  display: flex;
  .btn-toolbar {
    margin-right: 10px;
  }
  .doc-upload {
    a {
      color: unset;
      i {
        font-size: 17px !important;
      }
    }
  }
}
@media (max-width: 770px) {
  .wide-device {
    display: none;
  }
}

@media (min-width: 771px) {
  .small-device {
    display: none;
  }
}

.labelStyle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: top;
}

.labelStyle-mobile:hover {
  white-space: unset !important;
}

.labelPopoverFalse {
  display: none !important;
}

.labelPopover {
  z-index: 1;
}

.title {
  z-index: -1;
}

.fieldOrderPadding {
  padding-left: 30px;
}

.comments {
  margin: 10px 5px 10px 0px;
  background-color: antiquewhite;
  border-radius: 10px;
  box-shadow: 4px 4px lightgrey;
  display: grid;
  border-radius: 3px;
  .no-comment-container {
    text-align: center;
    margin: 5px 5px;
  }
  .comment-body {
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .comment-view {
    border-radius: 3px;
    max-width: 80%;
    resize: none;
    margin-top: 5px;
  }
  .user-name {
    margin-bottom: 5px;
    padding: 5px;
    float: left;
  }
  .post-date {
    margin-bottom: 5px;
    padding: 5px;
    float: right;
  }
}

.commentAlterButton {
  border-radius: 3px;
  margin-left: 5px;
  max-height: 33px;
  padding: 6px 10px;
}

.comment-box-title-container {
  padding-bottom: 25px;
}

.comments-container {
  text-align: center;
  min-width: 200px;
}

.comments-data-container {
  max-height: 325px;
  margin: 0px -10px;
  overflow-y: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.display-flex {
  display: flex;
}

.comment-delete-button {
  border-radius: 3px;
  background-color: #c29032;
  border-color: #c29032;
  padding: 6px 15px;
}

.comment-add-button {
  border-radius: 3px;
  margin-left: 5px;
  padding: 6px 15px;
}

.new-comment-input {
  border-radius: 3px;
  max-width: 80%;
}

.first-comment-divider {
  margin: 10px 10px;
}

.second-comment-divider {
  margin: 15px 15px;
  padding: 1px;
}

.formTopOptionsLoader {
  > div {
    > span {
      display: flex;
    }
  }
}

.file-selector-container {
  display: flex;
  align-items: center;
  .dropzone {
    border: 1px dotted #15375c;
    text-align: center;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    font-size: small;
    padding: 5px;
    width: 100%;
  }
  .has-error {
    border-color: red;
  }
  .file-selector {
    font-size: 25px;
    color: white;
    padding: 2px;
    margin: 2px;
  }
  .right {
    background-color: green;
  }
  .square {
    background-color: grey;
  }
}

.attachDocumentsList {
  display: flex;
  margin-top: 15px;
  flex-flow: wrap;
  max-height: 100px;
  overflow-y: auto;
}
.label-content {
  border-radius: 0.3rem !important;
  background-color: #0ba1f1 !important;
}

.mobile-view {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.custom-address-selector-option {
  display: flex;
  justify-content: space-between;
}

.report-company-selector {
  text-align: left;
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
}
