@media (max-width: 480px) {
	/*
	 * Base structure
	 */
	html,
	body {
		height: 100%;
		font-size: 14px;
		color: #333;
	}

	.App {
		.Navbar {
			background-color: rgba(1, 1, 40, 0.9);
			height: 100%;
			top: 0;
			right: 0;
			z-index: 9999;
			position: fixed;
			display: none;
			padding: 10vw 10%;
			width: 250px;
			.btn {
				display: block;
				text-align: left;
				border-color: rgba(255,255,255,0.6);
				border-width: 0 0 1px 0;
				margin-left: 0;
				float: left;
				width: 100%; 
			}
			.btn-primary {
					// background: transparent;
			}
			.btn-secondary {
					border-bottom: 0;
					margin-top: 10px;
			}
			>span {
					display: block;
					padding: 0;
			}
		}
		.menuButton {
			display: block !important;
                        background: #0c2030;
		}
		.menuButtonDesktop {
			display: none !important;
		}
		
		.btn {
			padding: 9px !important;
		}
		.teamList {
			.ourTeam {
				h2 {
					width: 100%;
					padding-left: unset;
					margin-bottom: 3rem;
				}
				.arrows.left {
					margin-left: calc(90% - 80px);
				}
				.arrows {
					margin-bottom: 15px;
				}
			}
		}
	}
}
