.ContactsPage {
  user-select: none;
  .contacts {
    background-color: #f2f3f7;
    text-align: center;
    &.contact-new {
      padding-top: 100px;
      padding-bottom: 50px;
    }

    .btn1 {
      height: 48px;
      border-radius: 3px;
      border: none;
      color: #fff;
      font-size: small;
      padding-left: 50px;
      padding-right: 50px;
      line-height: 40px;
    }

    .chk-field1 {
      margin-top: 35px;
      margin-bottom: 23px !important;
    }
    .chk-field2 {
      margin-bottom: 23px !important;
    }
    .chk-field3 {
      margin-bottom: 25px !important;
    }
    .contact-map {
      .map-cont {
        border: #cecece solid 1px;
        border-radius: 3px;
      }
      .map-cont2 {
        border-radius: 3px;
      }
      margin-top: 70px;
    }

    .row {
      text-align: left;
      margin: 0;

      .address {
        font-size: 14px;

        i {
          color: #f00;
          font-size: 18px;
          width: 1rem;
          display: inline-table;
          text-align: center;
        }
      }

      h3 {
        margin-top: 0px;
        margin-bottom: 48px;
      }
      input {
        vertical-align: top;
      }
	  
	  .form-control::placeholder{
		color: #6a6a6a;	  
	  }

      .form-control {
        width: 100%;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: 3px !important;
        height: 48px;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .form-group {
        margin-bottom: 10px;
      }
      .form-control:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      }

      label {
        font-size: small;
        font-weight: normal;
        max-width: 100%;
        margin-left: 00px;
        padding-left: 25px;
        position: relative;
        color: #000000;

        input {
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
    }
  }
  .contacts {
    .btn1 {
      padding: 0 !important;
      padding-left: 20px !important;
      padding-right: 25px !important;
      line-height: 35px !important;
      width: 171px;
    }
  }
}
